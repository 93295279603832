import React, { useState, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import './Contact.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [checkedRodo, setCheckedRodo] = useState(false);

  useEffect(() => {
    // Create map
    const map = L.map("map").setView([53.13577430144443, 23.149170499406463], 17);

    // Add OpenStreetMap tiles
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Custom marker
    const customIcon = L.divIcon({
      className: "custom-marker",
      html: '<i class="fa-solid fa-location-dot" style="color:#3a2d83;"></i>',
      iconSize: [80, 80],
      iconAnchor: [50, 20],
      popupAnchor: [-10, -30],
    });

    L.marker([53.13577430144443, 23.149170499406463], { icon: customIcon })
      .addTo(map)
      .bindPopup(`<div class="popup-content">
        <b>Gabinet Ginekologiczny</b><br>dr Elżbieta Olechno-Pełszyńska
      </div>`)
      .openPopup();

    return () => {
      map.remove();
    };
  }, []);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  };
  const constantValue = 'Ginekolog';
  // Handle RODO checkbox change
  const handleChangeRodo = () => {
    setCheckedRodo(!checkedRodo);
  };

  // Disable or enable submit button based on checkbox state
  useEffect(() => {
    setDisabled(!checkedRodo);
  }, [checkedRodo]);

  // Validate form
  const formValid = () => {
    return name.trim() !== '' && email.trim() !== '' && title.trim() !== '' && message.trim() !== '';
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid()) {
      const templateId = 'template_f0mck6a';  // Your emailjs template ID
      sendFeedback(templateId, {
        message,
        title,
        name,
        email,
        constantValue
      });
    } else {
      Swal("Błąd!", "Proszę wypełnić wszystkie pola formularza!", "error");
    }
  };

  // Send email through emailjs
  const sendFeedback = (templateId, variables) => {
    emailjs.send('service_ginekolog', templateId, variables, 'user_rAY4wDjJvuE4oIWtCnsbp')
    .then(res => {
        Swal.fire({
            icon: 'success',
            title: 'Poszło!',
            text: 'Twoja wiadomość została wysłana!'
        }).then(() => {
            setTimeout(() => {
                window.location.reload();
            }, 1200);
        });
    }).catch(err => {
        console.error('Email Error:', err);
    });
  };

  return (
    <div className="contact-container">
      <div className="contact-text">
        <h2 className="contact-tytul">Dane kontaktowe</h2>
        <div className="contact-details">
          <h2>Gabinet Ginekologiczny<br />Elżbieta Olechno-Pełszyńska</h2>
          <p><span>Adres:</span> ul. Al.Józefa Piłsudskiego 6/3, Białystok</p>
          <h2>Rejestracja telefoniczna 9:00 - 17:00</h2>
          <p><span>Telefon:</span> +48 887 404 404</p>
          <p><span>Email:</span> gab.lek.bialystok@gmail.com</p>
          
          <h2 style={{fontWeight:"800"}}>Dogodne godziny przyjęć, nawet w godzinach wieczornych po wcześniejszej rejestracji</h2>
          <div className="google-button">
            <p>Chcesz dowiedzieć się więcej? <br /> Zobacz nas na Google i sprawdź opinie naszych pacjentów!</p>
            <Link to="https://www.google.com/maps/place/Gabinet+Ginekologiczny+dr+El%C5%BCbieta+Olechno-Pe%C5%82szy%C5%84ska+Ginekolog+Bia%C5%82ystok/@53.1358268,23.1492541,17z/data=!3m1!4b1!4m6!3m5!1s0x471ffdc1b5cb813f:0xb13d80b340fc77e6!8m2!3d53.1358268!4d23.1492541!16s%2Fg%2F11pw7f8t9r?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D" className="navbar-logo">
              <button className="g-button"><i className="fa-brands fa-google"></i>oogle</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="contact-form">
        <h2 className="contact-tytul">Formularz kontaktowy</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Imię <em>&#x2a;</em></label>
            <input
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              required
              type="text"
              placeholder="Imię, Nazwisko"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Adres e-mail <em>&#x2a;</em></label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Temat <em>&#x2a;</em></label>
            <input
              id="title"
              name="title"
              value={title}
              onChange={handleChange}
              required
              type="text"
              placeholder="Tytuł"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Zapytanie <em>&#x2a;</em></label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={handleChange}
              required
              rows="4"
              placeholder="Wiadomość"
            />
          </div>
          <div>
            <input
              type="checkbox"
              id="rodo-contact"
              name="rodo-contact"
              checked={checkedRodo}
              onChange={handleChangeRodo}
            />
            <label id="rodo-checkbox" htmlFor="rodo-contact">
              Wyrażam zgodę na przetwarzanie moich danych osobowych w powyższym formularzu przez Gabinet Lekarski dr Elżbieta Olechno-Pełszyńska, ul. Al.Józefa Piłsudskiego 6/3, 15-445 Białystok.
            </label>
          </div>
          <input type="hidden" name="constantValue" value={constantValue} /> {/* Ukryte pole */}
          <input
              id="submit-contact"
              type="submit"
              value="Wyślij"
              disabled={disabled}
              className={`contact-submit ${disabled ? 'disabled' : ''}`}
            />
        </form>
      </div>

      <div id="map"></div>
    </div>
  );
};

export default Contact;




        // 'service_ginekolog'- service ID
        // 'template_f0mck6a'- template ID
      
        // 'user_rAY4wDjJvuE4oIWtCnsbp' - user ID
     
