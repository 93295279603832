import React from 'react';
import './Rodo.css';

function Rodo() {
    return (
        <div className='rodo-container'>
            <div className='rodo-text'>
            <h1>RODO </h1>
            <br></br>
            <h3>
            1. Administratorem Państwa danych osobowych zgromadzonych w dokumentacji medycznej jest Gabinet Lekarski, dr Elżbieta Olechno-Pełszyńska, przy ul. Al. Józefa Piłsudskiego 6/3 w Białymstoku, e-mai: gab.lek.bialystok@gmail.com.
            </h3>
            <br></br>
            <h3>2. Państwa dane osobowe takie jak imiona i nazwisko, PESEL, adres zamieszkania, płeć, data urodzenia, informacje o stanie zdrowia i udzielonych świadczeniach są przetwarzane na podstawie art. 6 ust. 1 lit. c) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), a więc dlatego, że Administrator ma obowiązek uzyskania tych danych i ujawnienia ich w dokumentacji medycznej na podstawie obowiązujących przepisów ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta. Państwa dane kontaktowe w postaci numeru telefonu lub adresu e-mail są przetwarzane na podstawie art. 6 ust. 1 lit. a) powołanego Rozporządzenia tj. na podstawie Państwa zgody.</h3>
            <br></br>
            <h3>3. Podanie przez Państwa numeru telefonu i adresu e-mail jest dobrowolne. Podanie pozostałych danych osobowych wymienionych w punkcie 2 powyżej jest obowiązkowe na podstawie ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta.</h3>
            <br></br>
            <h3>4. Administrator będzie przechowywać Państwa dane osobowe, których podanie jest obowiązkowe przez okres niezbędny do realizacji celów przetwarzania oraz wymagany przepisami prawa tj.: dane osobowe zawarte w dokumentacji medycznej papierowej lub elektronicznej – przez okres 20 lat, zdjęcia RTG – 10 lat, dane zawarte w skierowaniach – 5 lat, dane w terminarzu spotkań – 1 rok. Dane o numerze telefonu i adresie e-mail będą przechowywane przez okres 10 lat, jednak nie dłużej niż do czasu odwołania przez Państwa zgody na przetwarzanie tych danych.</h3>
            <br></br>
            <h3>5. Administrator będzie przekazywać Państwa dane osobowe swoim pracownikom (np. rejestratorowi), Pracowni Diagnostycznej i do Biura Rachunkowego. Takie podmioty przetwarzają dane na podstawie umowy z Administratorem i tylko zgodnie z jego poleceniami.</h3>
            <br></br>
            <h3>6. Państwa dane osobowe będą przetwarzane w sposób zautomatyzowany, jednakże nie będzie to wywoływać wobec Państwa żadnych skutków prawnych lub w podobny sposób istotnie wpływać na Państwa sytuację. Zautomatyzowane przetwarzanie danych wynika z posługiwania się przez Administratora programami do obsługi danych w systemach informatycznych (w tym dokumentacja medyczna w formie elektronicznej).</h3>
            <br></br>
            <h3>7. Przysługują Państwu następujące prawa związane z przetwarzaniem danych osobowych:
                <br></br>
                <p style={{marginLeft:"5%"}}>a) prawo do cofnięcia zgody na przetwarzanie danych osobowych w postaci numeru telefonu i adresu e-mail,</p>
                <p style={{marginLeft:"5%"}}>b) prawo dostępu do swoich danych osobowych,</p>
                <p style={{marginLeft:"5%"}}>c) prawo żądania sprostowania swoich danych osobowych,</p>
                <p style={{marginLeft:"5%"}}>d) prawo żądania usunięcia swoich danych osobowych,</p>
                <p style={{marginLeft:"5%"}}>e) prawo żądania ograniczenia przetwarzania swoich danych osobowych,</p>
                <p style={{marginLeft:"5%"}}>f) prawo do przenoszenia danych osobowych, tj. prawo otrzymania od Administratora swoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie informatycznym nadającym się do odczytu maszynowego. Mogą Państwo przesłać te dane innemu administratorowi danych lub zażądać, aby Administrator przesłał te dane do innego administratora. Jednakże Administrator zrobi to tylko jeśli takie przesłanie jest technicznie możliwe.</p>
                </h3>
            <br></br>
            <h3>8. Aby skorzystać z powyższych praw, należy się skontaktować z Administratorem – dane kontaktowe w pkt. 1 powyżej.</h3>
            <br></br>
            <h3>9. Przysługuje Państwu prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.</h3>
            <br></br>
            <h1>Polityka prywatności</h1>
            <br></br>
            <h3>Aby skutecznie świadczyć usługi i umożliwić dogodny kontakt z klientem za pomocą strony internetowej oraz formularza kontaktowego Gabinet Lekarski, dr Elżbieta Olechno-Pełszyńska, przy ul. Al. Józefa Piłsudskiego 6/3 w Białymstoku przetwarza Państwa dane osobowe zgodnie z prawem. 
                W celu kontaktu za pomocą formularza na stronie internetowej przetwarzamy takie dane osobowe, jak:
                <br></br>
                <p style={{marginLeft:"5%"}}>•	Imię</p>
                <p style={{marginLeft:"5%"}}>•	Nazwisko</p>
                <p style={{marginLeft:"5%"}}>•	Adres email</p>
                    Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit b RODO, który pozwala przetwarzać dane osobowe w celu kontaktu i obsługi zgłoszenia złożonego przy wykorzystaniu elektronicznego formularza kontaktowego
                </h3>
        </div>

    </div>
      );
    }
export default Rodo;
