import React, { useState } from 'react';
import './Gallery.css';
import { Carousel } from 'react-carousel-minimal';

function Gallery() {
  const data = [
    { image: "./galeria/1.jpg" },
    { image: "./galeria/2.jpg" },
    { image: "./galeria/3.jpg" },
    { image: "./galeria/4.jpg" },
    { image: "./galeria/5.jpg" },
    { image: "./galeria/6.jpg" },
    { image: "./galeria/7.jpg" },
    { image: "./galeria/8.jpg" },
    { image: "./galeria/9.jpg" },
    { image: "./galeria/10.jpg" },
    { image: "./galeria/11.jpg" },
    { image: "./galeria/12.jpg" },
    { image: "./galeria/13.jpg" },
    { image: "./galeria/14.jpg" },
    { image: "./galeria/15.jpg" },
    { image: "./galeria/16.jpg" },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  const captionStyle = {
    fontSize: '1.5em',
    fontWeight: 'bold',
  };

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  return (
    <div className="galeria-container">
      <div className="carousel-container">
        <Carousel
          data={data}
          time={2000}
          width="700px"
          height="500px"
          captionStyle={captionStyle}
          radius="10px"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          style={{
            width: "fit-content",
            margin: "40px auto"
          }}
          automatic={true}
          dots={true}
          slideIndex={selectedIndex}
        />
      </div>

      {/* Miniatury zdjęć w rzędzie lub siatce */}
      <div className="thumbnails-gallery">
        {data.map((item, index) => (
          <div
            className={`thumbnail-item ${selectedIndex === index ? 'selected' : ''}`}
            key={index}
            onClick={() => setSelectedIndex(index)}
          >
            <img
              src={item.image}
              alt={`Thumbnail ${index + 1}`}
              className="thumbnail-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
