import React from 'react';
import './About.css';

function About () {
    return (
  <div className="gin-container">
         
          


            <div className='gin-content1-text'>
            
                    <h1>Doświadczenie</h1>
                    <br></br>
                    <h2>Pracuję w zawodzie od niemal 40 lat. Ukończyłam w 1982 roku Akademię Medyczna w Białymstoku, a w 1991 roku osiągnełam II stopień specjalizacji ginekologii i położnictwa.</h2>
                    <br></br>
                    <h2>Oferuję opiekę medyczną dla Kobiet w różnym wieku (okres pokwitania, ciąża, okres przekwitania - klimakterium). Profilaktyka raka szyjki macicy, antykoncepcja, badania cytologiczne oraz badania USG (piersi, położnicze, ginekologiczne). Gabinet przy ul. Al. Jozefa Piłsudskiego 6/3 prowadzę od 2014 roku.                     </h2>
                    <br></br>
                    <h1 id="omnie">O mnie</h1>
                    <br></br>
                    <h2>Pragnę pomagać z zaangażowaniem oraz traktować wszystkie swoje pacjentki równie dobrze. Staram się zawsze wysłuchać oraz odpowiedzieć na wszelkie niejasności związane ze zdrowiem Kobiet, ponieważ sama nią jestem. </h2>
                    <br></br>
                   
                      <h2>Serdecznie dziękuję za zaufanie oraz wsparcie, które pozwoliło mi otrzymać nagrodę <span style={{color:"rgb(120, 74, 158)", fontWeight:"700"}}>Orły Medycyny 2023</span>.

                            Jest to dla mnie nie tylko wyraz uznania, ale przede wszystkim dowód, że praca z pasją i oddanie pacjentom przynoszą wymierne efekty. 
                            Serdecznie dziekuje Państwu.</h2>
                    <div className='gin-uslugi-div' >
                                <a href="https://www.orlymedycyny.pl/profile-677526-gabinet-ginekologiczny-dr-elzbieta-olechno-pelszynska-ginekolog-bialystok" target="_blank">
                                  <img src="https://www.orlymedycyny.pl/images/medals/677526/laureat300_black_2_pl.png"  alt="Gabinet Ginekologiczny dr Elżbieta Olechno-Pełszyńska Ginekolog Białystok -  Białystok" title="Gabinet Ginekologiczny dr Elżbieta Olechno-Pełszyńska Ginekolog Białystok -  Białystok"></img></a>
                                
                                
                              </div>  
                              
                         
                      </div>
                      
                      <div className='gin-content1'>
                          <img className="img-omnie" src="./IMG_8882.JPG" ></img> 
                          </div> 

            {/* <div className="gin-galeria-content">
      <div className="gin-galeria" style={{ width: "100%" }}>
      <h1 id="doswiadcznie" style={{marginTop:"5%"}}>Certyfikaty</h1>
        <br />
        <div className="gallery-container">
          <div className="stacked-images">
            <img src="./certyfikaty/1.1.jpg" alt="Certyfikat 1" style={{ "--i": 0 }} />
            <img src="./certyfikaty/2.2_1.jpg" alt="Certyfikat 2" style={{ "--i": 1 }} />
            <img src="./certyfikaty/3.3.jpg" alt="Certyfikat 3" style={{ "--i": 2 }} />
            <img src="./certyfikaty/4.4.jpg" alt="Certyfikat 4" style={{ "--i": 3 }} />
            <img src="./certyfikaty/5.5.jpg" alt="Certyfikat 5" style={{ "--i": 4 }} />
            <img src="./certyfikaty/6.6.jpg" alt="Certyfikat 6" style={{ "--i": 5 }} />
            <img src="./certyfikaty/7.jpg" alt="Certyfikat 7" style={{ "--i": 6 }} />
            <img src="./certyfikaty/8_1.jpg" alt="Certyfikat 7" style={{ "--i": 7 }} />
            <img src="./certyfikaty/9.jpg" alt="Certyfikat 7" style={{ "--i": 8 }} />
            <img src="./certyfikaty/10.jpg" alt="Certyfikat 7" style={{ "--i": 9 }} />
            </div></div></div> </div>
            <div className="gin-galeria-content">
            <div className="gin-galeria">
            <div className="gallery-container">
            <div className="stacked-images">
            
            <img src="./certyfikaty/11.jpg" alt="Certyfikat 7" style={{ "--i": 0 }} />
            <img src="./certyfikaty/12.jpg" alt="Certyfikat 7" style={{ "--i": 1}} />
            <img src="./certyfikaty/13.jpg" alt="Certyfikat 7" style={{ "--i": 2}} />
            <img src="./certyfikaty/14.jpg" alt="Certyfikat 7" style={{ "--i": 3}} />
            <img src="./certyfikaty/15.jpg" alt="Certyfikat 7" style={{ "--i": 4}} />
            <img src="./certyfikaty/16.jpg" alt="Certyfikat 7" style={{ "--i": 5}} />
            <img src="./certyfikaty/17.jpg" alt="Certyfikat 7" style={{ "--i": 6}} />
            <img src="./certyfikaty/18.jpg" alt="Certyfikat 7" style={{ "--i": 7}} />
            <img src="./certyfikaty/19.jpg" alt="Certyfikat 7" style={{ "--i": 8}} />
            </div></div></div></div>
            <div className="gin-galeria-content">
            <div className="gin-galeria">
            <div className="gallery-container">
            <div className="stacked-images">
            <img src="./certyfikaty/20.jpg" alt="Certyfikat 7" style={{ "--i": 0}} />
            <img src="./certyfikaty/21.jpg" alt="Certyfikat 7" style={{ "--i": 1}} />
            <img src="./certyfikaty/22.jpg" alt="Certyfikat 7" style={{ "--i": 2}} />
            <img src="./certyfikaty/23.jpg" alt="Certyfikat 7" style={{ "--i": 3}} />
            <img src="./certyfikaty/24.jpg" alt="Certyfikat 7" style={{ "--i": 4}} />
            <img src="./certyfikaty/25.jpg" alt="Certyfikat 7" style={{ "--i": 5}} />
            <img src="./certyfikaty/26.jpg" alt="Certyfikat 7" style={{ "--i": 6}} />
            <img src="./certyfikaty/27.jpg" alt="Certyfikat 7" style={{ "--i": 7}} />
            <img src="./certyfikaty/28.jpg" alt="Certyfikat 7" style={{ "--i": 8}} />
            <img src="./certyfikaty/29.jpg" alt="Certyfikat 7" style={{ "--i": 9}} />
            </div></div></div></div>
            <div className="gin-galeria-content">
            <div className="gin-galeria">
            <div className="gallery-container">
            <div className="stacked-images">
            <img src="./certyfikaty/30.jpg" alt="Certyfikat 7" style={{ "--i": 0}} />
            <img src="./certyfikaty/31.jpg" alt="Certyfikat 7" style={{ "--i": 1}} />
            <img src="./certyfikaty/32.jpg" alt="Certyfikat 7" style={{ "--i": 2}} />
            <img src="./certyfikaty/33.jpg" alt="Certyfikat 7" style={{ "--i": 3}} />
            <img src="./certyfikaty/34.jpg" alt="Certyfikat 7" style={{ "--i": 4}} />
            <img src="./certyfikaty/35.jpg" alt="Certyfikat 7" style={{ "--i": 5}} />
            <img src="./certyfikaty/36.jpg" alt="Certyfikat 7" style={{ "--i": 6}} />
            <img src="./certyfikaty/37.jpg" alt="Certyfikat 7" style={{ "--i": 7}} />
</div></div>
          
      </div>
    </div> */}
 </div>

 )
 }

 export default About;