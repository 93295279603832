import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [activeLink, setActiveLink] = useState(null); // Dodajemy stan dla aktywnego linku

  const handleClick = () => setClick(!click); // Zmieniamy stan kliknięcia (otwieranie/zamykanie menu)

  const closeMobileMenu = () => setClick(false); // Funkcja do zamykania menu po kliknięciu w link

  const handleLinkClick = (index) => {
    setActiveLink(index); // Ustawiamy aktywny link na kliknięty
    closeMobileMenu(); // Zamykamy menu mobilne po kliknięciu
  };

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> {/* Zmieniamy ikonę na podstawie stanu kliknięcia */}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}> {/* Dodajemy klasę active do menu, gdy jest otwarte */}
            <li className='nav-item'>
              <Link
                to='/o-mnie'
                className={`nav-links ${activeLink === 0 ? 'active' : ''}`}
                onClick={() => handleLinkClick(0)}
              >
                O mnie
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/cennik'
                className={`nav-links ${activeLink === 1 ? 'active' : ''}`}
                onClick={() => handleLinkClick(1)}
              >
                Cennik
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/gabinet'
                className={`nav-links ${activeLink === 2 ? 'active' : ''}`}
                onClick={() => handleLinkClick(2)}
              >
                Gabinet
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/rodo'
                className={`nav-links ${activeLink === 3 ? 'active' : ''}`}
                onClick={() => handleLinkClick(3)}
              >
                RODO
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/kontakt'
                className={`nav-links ${activeLink === 4 ? 'active' : ''}`}
                onClick={() => handleLinkClick(4)}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
