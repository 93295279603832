import React from 'react';
import './App.css';
import './Home.css';
import Fade from 'react-reveal/Fade';

function Home() {
  const data = [


        {image:"./galeria1.jpg",
          description:"Gabinet w centrum miasta",
          description1:"Skorzystaj z przyjaznego gabinetu, znajdującego się w samym sercu Białegostoku, przy Al. J. Piłsudskiego 6/3. Dogodne godziny przyjęć, nawet w godzinach wieczornych, po wcześniejszej rejestracji telefonicznej, mejlowej lub za pośrednictwe, formularza kontaktowego."
        },
        {image:"./lekarz.jpg",
          description:"Lekarz z doświadczeniem",
          description1:"Dr Elżbieta Olechno-Pełszyńska to doświadczony ginekolog z wieloletnią praktyką, specjalizujący się w diagnostyce, leczeniu oraz profilaktyce zdrowia kobiecego. Z pasją i zaangażowaniem wspiera pacjentki na każdym etapie życia – od pierwszych wizyt ginekologicznych, aż po leczenie problemów hormonalnych i menopauzalnych."
        },
        {image:"./platnosc.jpg",
          description:"Akceptujemy gotówkę oraz kartę",
          description1:"W naszym gabinecie akceptujemy płatności zarówno gotówką, jak i kartą. Dbamy o Twój komfort i umożliwiamy wybór najdogodniejszej formy rozliczenia za świadczone usługi."
        },
  ];

  return (
     <div className="container">
    {data.map((item, index) => (
        <Fade left>
          <div className="card" key={index}>
            <img src={item.image} alt={`Obrazek ${index + 1}`} className="card-img" />
            <p className="description">{item.description}</p>
            <p className="description-text">{item.description1}</p>
        </div></Fade>
    ))}
</div>
  );
}

export default Home;