import React from 'react';
import './App.css';
import './Header.css';
import {Link} from 'react-router-dom';

function Header() {
    return (
      <div className='header-container'sticky="top">
        <Link to='/' className='navbar-logo'><div className='header-tytul'>
        <h2>Elżbieta Olechno-Pełszyńska</h2>
            <h3>SPECJALISTA POŁOŻNICTWA I GINEKOLOGII</h3>
        </div></Link>

        <div className='header-adres'>
            <h2>Lokalizacja</h2>
            <h3>Aleja Józefa Piłsudskiego 6/3</h3>
            <h3>15-445 Białystok</h3>
        </div>
        <div className='header-adres'>
            <h2>Rejestracja</h2>
            <h3>Poniedziałek - Piątek</h3>
            <h3 style={{color:"#3a2d83", fontWeight:"bolder"}}>887 404 404</h3>
        </div>
    </div>
  );
}

export default Header;