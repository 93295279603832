import React from 'react';
import './Price.css';

function Price () {
    return (
        <div className='price'>
      
                          
        <div className='price-text'>
       
          
                <table className='pierwsza-tabela'>
                        <tr>
                            
                            <th style={{width:"75%"}}>Usługi</th>
                            <th >Cena</th>
                        </tr>
                        <tr>
                            <td >I wizyta (wywiad, badanie ginekologiczne, ocena biocenozy (stopień czystości)) </td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>200 zł</td>
                        </tr> 
                        <tr>
                            <td >Konsultacja ginekologiczna </td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>150 zł</td>
                        </tr> 
                        <tr>
                            <td >Konsultacja położnicza </td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>250 zł</td>
                        </tr>
                       
                        <tr>
                            <td >Badanie cytologiczne (profilaktyka raka szyjki macicy)</td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>od 50 zł</td>
                        </tr>
                        <tr>
                            <td >USG (ginekologiczne)</td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>100 zł</td>
                        </tr>
                        <tr>
                            <td >USG (położnicze)</td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>250 zł</td>
                        </tr>
                        <tr>
                            <td  >Założenie wkładki domacicznej</td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>500 zł + cena wkładki</td>
                        </tr>
                        <tr>
                            <td  >Wyjęcie wkładki domacicznej</td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>100 zł</td>
                        </tr>
                     
                        <tr>
                            <td >Przedłużenie recepty bez wizyty </td>
                            <td style={{textAlign:"center", backgroundColor:"#edeaff"}}>50 zł</td>
                        </tr>
                    
                </table>

                <br></br>  
                <h4 >* Gabinet posiada uprawnienia do wydawania recept refundowanych oraz zwolnień lekarskich. W ramach konsultacji ginekologicznej, wykonuje stopień czystości.</h4>
            
</div>

{/* <div className='gin-uslugi-div'style={{display:"inline-block"}} >
                        <div className='gin-content1-text' >
                          <br></br>
                          <br></br>
                          <h1>Cytologia</h1>
                          <br></br>
                          <h2>Cytologia szyjki macicy jest badaniem specjalistycznym, pomaga wykryć m.in. wirusa HPV, który ma bezpośredni związek z rozwojem choroby nowotworowej. 
                            Cytologia powinna być wykonywana regularnie. Badanie jest wykonywane poprzez wziernik, a także szczoteczkę, którą pobiera się materiał do analizy. 
                            Wymaz pobrać można co najmniej 2 dni po zakończonej miesiączce lub najpóźniej 4 dni przed kolejną miesiączką. 
                            Optymalnym czasem do pobrania próbki jest pierwsza połowa cyklu. </h2>
                            
                            <br></br>
                            <br></br>
                            <h1>USG ginekologiczne</h1>
                          <br></br>
                          <h2>USG ginekologiczne jest badaniem obrazowym, zlecanym przez lekarza, jest badaniem profilaktycznym jak np. cytologia. Pozwala sprawdzić stan kobiecych narządów rodnych. Jak działa USG? Głowica aparatu USG wysyła sygnał przechodzący przez tkanki, a jej odbicie powraca do odbiornika, który przetwarza falę na obraz.
                            Są dwa rodzaje badania USG, transwaginalne (dopochwowe) oraz przezbrzuszne. USG waginalne jest bardziej dokładne. USG ginekologiczne najlepiej wykonać kilka dni po zakończeniu miesiączki. A przygotowując się do badania, najlepiej jest opróżnić swój pęcherz moczowy.
                            USG ginekologiczne jest bezpieczne i nieinwazyjne. Przeciwskazaniem do jego wykonania są otwarte rany pochwy oraz brak zgody pacjentki na jego wykonanie.</h2>
</div>
</div> */}
</div>
    )
}

export default Price;