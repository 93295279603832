import './App.css';
import React from 'react';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Footer from './Footer';
import About from './About';
import Navbar from './Navbar';
import Price from './Price';
import Home from './Home';
import Gallery from './Gallery';
import Rodo from './Rodo';
import Contact from './Contact';

function App() {
  return (
    <>
     <BrowserRouter>
     <Header sticky="top"/>
     <Navbar/>
     <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/o-mnie' element={<About/>} />
          <Route path='/cennik' element={<Price/>} />
          <Route path='/gabinet' element={<Gallery/>} />
          <Route path='/rodo' element={<Rodo/>} />
          <Route path='/kontakt' element={<Contact/>} />
          </Routes>
          <Footer />
          </BrowserRouter>
    </>
  );
}

export default App;
