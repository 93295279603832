import React from 'react';
import './App.css';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container' >
      <div className='footer-tytul'>
            <h2>Gabinet Ginekologiczny</h2>
            <h3>Elżbieta Olechno-Pełszyńska</h3>
        </div>

        <div className='footer-adres'>
            <h2>Lokalizacja <i class="fa-solid fa-location-dot"></i></h2>
            
            <h3>Aleja Józefa Piłsudskiego 6/3</h3>
            <h3>15-445 Białystok</h3>
        </div>
        <div className='footer-adres'>
            <h2>Rejestracja telefoniczna <i class="fa-solid fa-phone"></i></h2>
            
            <h3>Poniedziałek - Piątek</h3>
            <h3>9:00-17:00</h3>
            <h3 >887 404 404</h3>
        </div>
        <div className='footer-adres'>
            <h2>Płatność <i class="fa-solid fa-money-bill"></i></h2>
           
            <h3>Gotówka</h3>
            <h3>Karta</h3>
        </div>
    </div>
  );
}

export default Footer;